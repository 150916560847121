/* eslint-disable react/prop-types */
import {
  CardContent,
  Container, Grid, Grow, ListItem, Typography,
} from '@mui/material';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { WhoWeAre, Wrapper } from '../components';
import useStyles from '../styles';

function AboutSection({
  image,
}) {
  const classes = useStyles();
  return (
    <Grow in timeout={1000}>
      <div className={`${classes.whiteSmokeBackground}`}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          align="center"
        >
          <Grid item sm={6} xs={12}>
            <GatsbyImage
              image={image}
              alt="Hero Image"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Link
              to="/contact"
              className={classes.link}
            >
              <ListItem button className={`${classes.flexCenter}`}>
                <Container maxWidth="sm">
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      color="primary"
                      gutterBottom
                    >
                      Whether you are looking to sell your existing home or remodel it for
                      potential investment, Posh Pads is the place to start. Our full-time
                      realtors on-staff can assist with any questions you have about purchasing
                      or selling and can help you figure out what steps to take to prepare
                      along the way. Get started today and request a consultation online here.
                    </Typography>
                  </CardContent>
                </Container>
              </ListItem>
            </Link>
          </Grid>
        </Grid>
      </div>
    </Grow>
  );
}

export default function AboutPage({ data }) {
  const {
    aboutUsFeaturedImage,
  } = data;

  const gatsbyImage = getImage(aboutUsFeaturedImage);
  return (
    <Wrapper title="About Us">
      <Grid
        container
        direction="row"
        justifyContent="center"
        align="center"
      >
        <Grid item xs={12}>
          <WhoWeAre />
        </Grid>
        <Grid item xs={12}>
          <AboutSection image={gatsbyImage} />
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export const query = graphql`
  {
    aboutUsFeaturedImage: file(relativePath: {regex: "/14-print-2048 BATTLE ROW FINAL--6.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          width: 4000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;
